import React from "react";

import PageView from "@components/View/PageView";
import CardView from "@components/View/CardView";
import Wrapper from "@components/Wrapper";

import WantedCard from "./components/WantedCard";
import PositionCard from "./components/PositionCard";
import RecruitProcessCard from "./components/RecruitProcessCard";

const RecruitModule = () => (
  <Wrapper>
    <PageView title="올리브영에 합류할 당신을 기다리고 있습니다.">
      <CardView title="상시 채용 프로세스">
        <RecruitProcessCard />
      </CardView>
      <CardView title="이런 분을 모시고 싶어요">
        <WantedCard />
      </CardView>
      <CardView title="올리브영 Tech, 언제나 채용 중!">
        <PositionCard />
      </CardView>
    </PageView>
  </Wrapper>
);

export default RecruitModule;

import React, { ReactNode } from "react";
import * as styles from "./PageView.module.scss";
interface PageViewType {
  title?: string;
  children: ReactNode;
}

const PageView = ({ title, children }: PageViewType) => {
  return (
    <section className={styles.page}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <article>{children}</article>
    </section>
  );
};

export default PageView;

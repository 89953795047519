// extracted by mini-css-extract-plugin
export var apply = "PositionCard-module--apply--0be71";
export var arrow_up = "PositionCard-module--arrow_up--3d77e";
export var blank = "PositionCard-module--blank--cb11d";
export var button = "PositionCard-module--button--7d4b6";
export var container = "PositionCard-module--container--b4f71";
export var content = "PositionCard-module--content--f4104";
export var content_active = "PositionCard-module--content_active--c5c71";
export var icon = "PositionCard-module--icon--130b8";
export var info = "PositionCard-module--info--d55ef";
export var main = "PositionCard-module--main--783a8";
export var sub = "PositionCard-module--sub--266b5";
export var tit = "PositionCard-module--tit--4fa29";
export var title = "PositionCard-module--title--17027";
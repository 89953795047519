import React from "react";
import * as styles from "./RecruitProcessCard.module.scss";

const recruitProcessData = [
  {
    index: "01",
    backgounrdImg: "process01.png",
    label: "서류전형",
    description: "지원 자격 및 자기소개서 기반의 평가를 합니다.",
  },
  {
    index: "02",
    label: "기술 Track",
    description:
      "올리브영의 기술 Track 면접은 CJAT 인성검사와 실무진이 참여하는 심층 면접으로 이루어져 있습니다.",
    detail: ["CJAT 인성검사", "Track1 - 코딩테스트", "Track2 - 개발 실무 리더"],
  },
  {
    index: "03",
    backgounrdImg: "process03.png",
    label: "임원 인터뷰",
    description: "조직과 직무에 적합한 인재를 채용합니다.",
  },
  {
    index: "04",
    backgounrdImg: "process04.png",
    label: "최종합격",
    description:
      "레퍼런스 체크 및 처우 협의, 채용 검진 후 입사가 최종 합격 됩니다.",
    detail: ["레퍼런스 체크", "처우 협의", "채용 검진"],
    color: "#90d101",
  },
];

const RecruitProcessCard = () => {
  return (
    <section className={styles.container}>
      {recruitProcessData.map((process) => (
        <article
          className={styles.item}
          key={`process_item_${process.index}`}
          style={{ backgroundColor: `${process.color}` }}
        >
          <p className={styles.rank}>{process.index}</p>
          <p className={styles.title}>{process.label}</p>
          {process.backgounrdImg && (
            <img
              src={`/assets/background/bg_${process.backgounrdImg}`}
              className={`${styles.icon} ${process.color ? styles.icon2 : ""}`}
              alt={`올리브영 테크 블로그 채용 프로세스 ${process.label}`}
            />
          )}
          <div className={styles.info}>
            {process.detail?.map((data) => (
              <p
                className={styles.detail}
                key={`올리브영 디지털사업본부 채용프로세스 ${data}`}
              >
                {data}
              </p>
            ))}
          </div>
        </article>
      ))}
    </section>
  );
};

export default RecruitProcessCard;

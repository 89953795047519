import React from "react";
import * as styles from "./WantedCard.module.scss";

const wantedData = [
  { icon: "ic_calendar.png", title: "스크럼과 코드 리뷰에 거부감이 없으신 분" },
  {
    icon: "ic_book.png",
    title: "새로운 기술을 습득하고 적용하는 것에 즐거움을 느끼시는 분",
  },
  {
    icon: "ic_paper.png",
    title: "자신의 발자취를 문서로 남기는 것이 익숙하신 분",
  },
  {
    icon: "ic_smile2.png",
    title: "부드러운 대화와 적극적인 토론이 즐거우신 분",
  },
  { icon: "ic_folder.png", title: "연차 보다는 가능성을 많이 품고 계시는 분" },
];

const WantedCard = () => {
  return (
    <section className={styles.container}>
      {wantedData.map((data) => (
        <div
          className={styles.item}
          key={`올리브영 디지털사업본부 인재상 ${data.title}`}
        >
          <div className={styles.wrapper}>
            <img
              src={`/assets/icon/${data.icon}`}
              className={styles.icon}
              alt={`올리브영 테크 블로그 채용 인재상 ${data.title}`}
            />
          </div>
          <div className={styles.title}>{data.title}</div>
        </div>
      ))}
    </section>
  );
};

export default WantedCard;

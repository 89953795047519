import React, { useState } from "react";

import * as styles from "./PositionCard.module.scss";
import Category, { CategoryType } from "@components/Category";

enum Position {
  ALL,
  BACK,
  FRONT,
  ANDROID,
  IOS,
  DEVOPS,
  DBA,
  QA,
}

type PositionCardType = {
  type: number;
  position?: string[];
  title: string;
  subtitle: string;
  overview: string[];
  requirement: string[];
  useful: string[];
  url: string;
};

type PositionItemInfoType = {
  title: string;
  list: string[];
};

const position = [
  {
    type: Position.BACK,
    title: "[경력] 온라인몰 백엔드 개발자(주문/결제)",
    subtitle:
      "올리브영 온라인몰 개발을 함께하실 백엔드 서버 개발자를 모십니다!",
    overview: [
      "확장 가능하고 안정적인 E-commerce Platform의 주문/결제/배송/정산 영역 Service 개발",
      "다른 엔지니어링 팀과 협업하여 Architecture 개선 및 수평적인 확장 수행",
      "성능개선을 위해 가설을 수립하고 검증하며 온라인 몰의 알려진 혹은 잠재적인 문제 해결",
    ],
    requirement: [
      "WEB 환경에 대한 기본적인 이해 및 지식",
      "3년 이상의 JAVA 기반의 웹 어플리케이션 개발 경험",
      "알려진 문제 혹은 잠재적인 문제를 해결하기 위해 가설을 수립하고 수행한 경험",
      "새로운 도전을 좋아하시는 분",
      "현업, PO, QA등 유관 부서 및 기능 조직과 협업 및 역할에 대한 이해",
    ],
    useful: [
      "온라인몰 주문/결제, 배송 프로세스를 잘 이해하고 관련 업무를 수행한 경험",
      "Spring Boot 를 이용한 프로젝트 수행 경험",
      "ORM(JPA/Hibernate)에 대한 이해",
    ],
    url: "https://www.wanted.co.kr/wd/108397",
  },
  // {
  //   type: Position.BACK,
  //   title: "[경력] 온라인몰 백엔드 개발자(전시/검색)",
  //   subtitle:
  //     "올리브영 온라인몰 개발을 함께하실 백엔드 서버 개발자를 모십니다!",
  //   overview: [
  //     "확장 가능하고 안정적인 E-commerce Platform의 전시 영역 Service 개발",
  //     "다른 엔지니어링 팀과 협업하여 Architecture 개선 및 수평적인 확장 수행",
  //     "성능개선을 위해 가설을 수립하고 검증하며 온라인 몰의 알려진 혹은 잠재적인 문제 해결",
  //   ],
  //   requirement: [
  //     "알려진 문제 혹은 잠재적인 문제 해결을 위해 가설을 수립하고 수행한 경험",
  //     "새로운 도전을 좋아하시는 분",
  //     "3년 이상의 백엔드 애플리케이션 개발 경력",
  //     "여러 프로그래밍 언어와 환경에 대한 기본적인 이해",
  //     "실시간 트래픽 처리와 분산 시스템에 관한 이해",
  //   ],
  //   useful: [
  //     "Spring Boot 를 이용한 프로젝트 수행 경험",
  //     "Kotlin/Java 를 이용한 기능 개발 경험",
  //     "Docker, Kubernetes 등 컨테이너 기반 기술을 이용한 배포/운영 경험",
  //     "ORM(JPA/Hibernate)에 대한 이해",
  //     "Microservices 설계 경험",
  //     "Cassandra, Mongo DB 등의 NoSQL 환경에서의 설계/개발/운영 경험",
  //   ],
  //   url: "https://recruit.cj.net/",
  // },
  {
    type: Position.BACK,
    title: "[경력]  O2O플랫폼 백엔드 개발자",
    subtitle:
      "올리브영 온라인몰 개발을 함께하실 백엔드 서버 개발자를 모십니다!",
    overview: [
      "올리브영의 커머스 서비스를 원활하게 운영하기 위한 O2O플랫폼 시스템의 백엔드 개발 업무",
      "시스템 Micro Service 분리 작업",
      "WMS를 제외한 발주/입고처리/재고관리/이동관리 등의 물류 흐름을 좌우하는 SCM에 대한 운영/개발",
      "올리브영 파트너 시스템 구축",
      "온/오프라인 쿠폰 서비스 개발 및 운영",
      "상품 마스터를 통해 해당 상품이 전시되기까지의 과정을 관리",
      "영업관리시스템 운영 및 개발",
    ],
    requirement: [
      "관련 부서와 커뮤니케이션을 하고 주도적으로 업무 추진이 가능한 팀 플레이어",
      "기술적인 문제에 대해 정확히 파악하고 논리적으로 분석하여 직접 문제 해결이 가능한 분",
      "Java, Spring Framework 기반 웹서비스에 대한 이해와 실무 개발 경험",
      "SpringBatch를 통한 배치 처리 경험이 있으신 분",
      "동기/비동기 처리에 대한 연동 경험이 있으신 분",
      "분산환경에서 대규모 데이터 처리를 위한 데이터 프로세싱 등에 대한 이해 및 경험 가진 분",
      "새로운 기술에 대해 빠르게 학습하고 조직내에 전파할 수 있는 신기술 도입에 적극적인 분",
    ],
    useful: [
      "Redis 등 Cache 관련 구축/운영 경험자",
      "MQ 나 Kafka 등의 비동기 처리 Stream Engine 운영/개발 경험이 있으신 분",
      "이커머스 백엔드 및 물류 시스템 관련 실무 경험이 있는 분",
      "Docker 등 DevOps환경 기반 컨테이너 및 Public 클라우드 인프라 경험자",
      "Agile 프로세스 및 DevOps 경험자",
    ],
    url: "https://www.wanted.co.kr/wd/108401",
  },
  {
    type: Position.FRONT,
    title: "[경력] 프론트엔드 개발자",
    subtitle: "올리브영 온라인몰 개발을 함께하실 프론트엔드 개발자를 모십니다!",
    overview: [
      "고객 경험 기반의 신규/기술 개선 프로젝트 수행",
      "올리브영 플랫폼에 필요한 신규 기술 적용",
      "이커머스 서비스 개발",
      "Front-end 설계 및 개발",
    ],
    requirement: [
      "관련 경력 3년 이상",
      "JavaScript 및 React와 같은 Front-end Framework 이해 및 개발 경험",
      "모바일 웹 및 Single page Application에 대한 관심 및 프로젝트 경험",
      "우수한 커뮤니케이션 스킬과 협업 자세 보유",
    ],
    useful: [
      "컴퓨터공학 관련 학위 소지자",
      "Typescript/React.js/Node.js 를 활용한 프로젝트 경험이 있는 분",
      "대규모 서비스 개발 프로젝트에서 Front-end 성능 최적화 경험이 있는 분",
      "이커머스 또는 패션/뷰티 관련 도메인에서 모바일 서비스 프로젝트 참여 경험이 있는 분",
    ],
    url: "https://www.wanted.co.kr/wd/79007",
  },
  // {
  //   type: Position.ANDROID,
  //   title: "[경력] Android 앱 개발자",
  //   subtitle: "올리브영 모바일 앱 개발을 함께하실 Android 개발자를 모십니다!",
  //   overview: [
  //     "올리브영 Android 모바일 앱 설계 및 개발",
  //     "기본 서비스 혹은 신규 서비스들의 Native 앱 설계 및 구축",
  //     "앱 공통 아키텍쳐 설계 및 공통 모듈 개발",
  //     "Android/모바일 개발 기술 트렌드 이해 및 조직 내 전파",
  //   ],
  //   requirement: [
  //     "관련 경력 3년 이상",
  //     "Java/Kotlin 기본에 대한 이해 보유",
  //     "Android 클라이언트 개발 철학에 대한 이해 및 관심 보유",
  //     "우수한 커뮤니케이션 스킬과 협업 자세 보유",
  //     "문제 해결 의지와 책임감 보유",
  //   ],
  //   useful: [
  //     "MVP, MVVM 등 architecture 설계 및 개발 경험",
  //     "UI 테스트 자동화에 대한 경험 및 관심 보유",
  //     "이커머스 또는 패션/뷰티 관련 도메인에서 모바일 서비스 프로젝트 참여 경험",
  //     "Android 모바일 앱 서비스 퍼블리싱 및 론칭 경험",
  //   ],
  //   url: "https://recruit.cj.net/",
  // },
  // {
  //   type: Position.IOS,
  //   title: "[경력] iOS 앱 개발자",
  //   subtitle: "올리브영 모바일 앱 개발을 함께하실 iOS 개발자를 모십니다!",
  //   overview: [
  //     "올리브영 iOS 모바일 앱 설계 및 개발",
  //     "기본 서비스 혹은 신규 서비스들의 Native 앱 설계 및 구축",
  //     "iOS/모바일 개발 기술 트렌드 이해 및 조직 내 전파",
  //   ],
  //   requirement: [
  //     "관련 경력 3년 이상",
  //     "Swift/Objective-C 기본에 대한 이해 보유",
  //     "iOS 클라이언트 개발 철학에 대한 이해 및 관심 보유",
  //     "우수한 커뮤니케이션 스킬과 협업 자세 보유",
  //     "문제 해결 의지와 책임감 보유",
  //   ],
  //   useful: [
  //     "UI 테스트 자동화에 대한 경험 및 관심 보유",
  //     "이커머스 또는 패션/뷰티 관련 도메인에서 모바일 서비스 프로젝트 참여 경험",
  //     "모바일 앱 서비스 퍼블리싱 및 론칭 경험",
  //   ],
  //   url: "https://recruit.cj.net/",
  // },
  // {
  //   type: Position.DEVOPS,
  //   title: "[경력] SRE(DevOps)",
  //   subtitle: "올리브영 온라인몰 DevOps 개발자를 모십니다!",
  //   overview: [
  //     "안정적 서비스 운영을 위한 아키텍쳐와 모니터링, 효율화",
  //     "지속적인 서비스 성능 개선을 위한 인프라/애플리케이션 모니터링 지표 수집, 정의",
  //     "수집된 지표를 기반으로 성능 및 비용 개선을 위한 인프라 아키텍쳐 디자인 및 마이그레이션 지원",
  //     "대용량 트래픽 처리를 위한 점진적인 인프라의 개선",
  //     "서비스 긴급 장애 대응 및 등급 관리, 재발 방지 등의 리스크 관리",
  //     "서비스 개발팀과 협력하여 CI/CD 시스템 구축 및 개선",
  //     "퍼블릭 클라우드 마이그레이션",
  //     "On-premise 환경의 인프라 시스템을 AWS/Azur 퍼블릭 클라우드로 마이그레이션",
  //     "컨테이너 및 다양한 IaaS 기반으로 개발 효율성 향상 및 DevOps 구축",
  //   ],
  //   requirement: [
  //     "논리적이고 체계적인 문제해결 능력 및 커뮤니케이션 능력을 보유하신 분",
  //     "백엔드 개발 또는 인프라 엔지니어 경력 2년 이상",
  //     "새로운 기술 및 트렌드를 배우고 적용하는 것을 즐기는 분",
  //     "Linux 서버 운영 경험 및 네트워크에 대한 충분한 이해를 보유하신 분",
  //   ],
  //   useful: [
  //     "AWS, GCP, Azure 중 하나 이상의 클라우드 아키텍처 구성 경험",
  //     "IaC(Infrastructure as Code, Terraform/chef/puppet)을 이용한 인프라 관리",
  //     "서비스 모니터링 및 운영 자동화를 통한 운영 효율 개선 경험",
  //     "이커머스 환경에서 대용량 트래픽에 대한 성능개선 유 경험자",
  //   ],
  //   url: "https://recruit.cj.net/",
  // },
  // {
  //   type: Position.DBA,
  //   title: "[경력] DBA",
  //   subtitle: "올리브영 온라인몰 DBA를 모십니다!",
  //   overview: [
  //     "데이터베이스 엔지니링 및 운영 업무(오라클/Mysql)",
  //     "데이터베이스 성능 튜닝, 트랜잭션 시스템의 아키텍처 설계 및 리뷰",
  //     "클라우드 및 on-premise 환경에서 시스템 안정성을 위한 모니터링 및 분석",
  //     "MySQL/Oracle 데이터베이스 클러스터링, HA 등 시스템 운영",
  //     "데이터 백업 및 재해/장애 발생시 복구 정책 수립 및 운영",
  //     "온라인 서비스 개발 지원",
  //     "데이터 구조화 및 테이블 설계 검토 및 표준화",
  //     "SQL 최적화, 사전 검수 및 인덱스 설계",
  //   ],
  //   requirement: [
  //     "빠르게 문제를 파악하고 논리적으로 분석하여 문제를 해결할 수 있는 역량을 가진 분",
  //     "다양한 유관부서와 커뮤니케이션하고 협업할 수 있는 소양을 가지고 계신 분",
  //     "Oracle/MYSQL등 RDBMS 운영에 대해 (백업/복구, 트러블슈팅) 전반적으로 이해하고 서비스 관점으로 운영할 수 있는 분",
  //     "SQL/인덱스 튜닝에 대한 기본적인 지식 또는 경험이 있으신 분",
  //   ],
  //   useful: [
  //     "Cloud(AWS/GCP/Azure 등) 환경 내 DBMS 운영관리 유경험자",
  //     "DB관리 자동화를 위한 스크립트 개발역량(Shell, Python 등)",
  //     "이커머스 환경에서 대용량 트래픽에 대한 성능개선 유 경험자",
  //     "Redis, MongoDB와 같은 NoSQL 경험 또는 관심",
  //   ],
  //   url: "https://recruit.cj.net/",
  // },
  // {
  //   type: Position.QA,
  //   title: "[경력] QA Manager(O2O플랫폼)",
  //   subtitle: "올리브영 온라인몰 QA Manager를 모십니다!",
  //   overview: [
  //     "올리브영 O2O 커머스 플랫폼의 개발 모든 과정에 참여하여 전반적인 서비스의 품질을 높이기 위한 작업 수행",
  //     "서비스 구축 후 배포의 전 과정에서 서비스의 품질 수준 구체화, 측정, 모니터링, 추적, 피드백을 통한 품질 수준 개선",
  //     "올리브영만의 서비스 개발 QA 프로세스를 정립",
  //     "테스트 자동화 구축 및 테스트 자동화 운영",
  //   ],
  //   requirement: [
  //     "관련 부서와 커뮤니케이션을 하고 주도적으로 업무 추진이 가능한 팀 플레이어",
  //     "기술적인 문제에 대해 정확히 파악하고 논리적으로 분석하여 직접 문제 해결이 가능한 분",
  //     "완성된 기획/디자인 문서를 통해 테스트 계획 수립 및 테스트 케이스 도출이 가능하신 분",
  //     "유관 경력 3년 이상",
  //   ],
  //   useful: [
  //     "커머스 플랫폼 관련 QA 또는 개발 실무 경험이 있는 분",
  //     "테스트 자동화 구축 및 운영 경험이 있으신 분",
  //   ],
  //   url: "https://recruit.cj.net/",
  // },
];

const PositionItemInfo = ({ title, list }: PositionItemInfoType) => {
  return (
    <div className={styles.info}>
      <div className={styles.title}>{title}</div>
      <ul>
        {list.map((text) => (
          <li key={`position_item_${title}_${text}`}>{text}</li>
        ))}
      </ul>
    </div>
  );
};

const PositionItem = ({
  title,
  subtitle,
  position,
  overview,
  requirement,
  useful,
  url,
}: PositionCardType) => {
  const [isAccodian, setAccodian] = useState(false);

  const handleClickPositionItem = () => {
    setAccodian(!isAccodian);
  };

  return (
    <>
      <div className={styles.container} onClick={handleClickPositionItem}>
        <div className={styles.main}>
          <div className={styles.tit}>{title}</div>
          <div className={styles.sub}>{subtitle}</div>
        </div>
        <div
          className={`${styles.icon} ${isAccodian ? styles.arrow_up : ""}`}
        />
      </div>
      <div
        className={` ${isAccodian ? styles.content_active : styles.content}`}
      >
        {position && <PositionItemInfo title="포지션 소개" list={overview} />}
        <PositionItemInfo title="하시게 될 업무" list={overview} />
        <PositionItemInfo
          title="아래 기준에 맞는 분을 찾습니다"
          list={requirement}
        />
        <PositionItemInfo title="이런 분이면 더욱 좋습니다" list={useful} />
        <div className={styles.apply}>
          <div className={styles.blank}></div>
          <a href={url} className={styles.button} target="_blank">
            지원하기
          </a>
        </div>
      </div>
    </>
  );
};

const PositionCard = () => {
  const [category, setCategory] = useState<CategoryType[]>([
    { title: "전체", type: Position.ALL, isActive: true },
    { title: "백엔드", type: Position.BACK, isActive: false },
    { title: "프론트엔드", type: Position.FRONT, isActive: false },
    { title: "iOS", type: Position.IOS, isActive: false },
    { title: "안드로이드", type: Position.ANDROID, isActive: false },
    { title: "DevOps", type: Position.DEVOPS, isActive: false },
    { title: "DBA", type: Position.DBA, isActive: false },
    { title: "QA Manager", type: Position.QA, isActive: false },
  ]);

  const [positionData, setPositionData] = useState(position);

  const handleCategoryClick = (type: number) => {
    setCategory(
      category.map((cate) =>
        cate.type === type
          ? { ...cate, isActive: true }
          : { ...cate, isActive: false }
      )
    );

    setPositionData(
      type === Position.ALL
        ? position
        : position.filter((data) => data.type === type)
    );
  };

  return (
    <>
      {/* {category.map((item) => (
        <Category
          key={`welfare_cate_${item.title}`}
          title={item.title}
          type={item.type}
          isActive={item.isActive}
          onCategoryClick={handleCategoryClick}
        />
      ))} */}
      {positionData.map((position) => (
        <PositionItem {...position} key={position.title} />
      ))}
    </>
  );
};

export default PositionCard;

import React, { ReactNode } from "react";
import * as styles from "./CardView.module.scss";
interface CardViewType {
  title?: string;
  children: ReactNode;
}

const CardView = ({ title, children }: CardViewType) => {
  return (
    <section className={styles.card}>
      <p className={styles.title}>{title}</p>
      <article>{children}</article>
    </section>
  );
};

export default CardView;

import React from "react";
import Seo from "@components/Seo";
import Layout from "@components/Layout";

import RecruitModule from "@modules/Recruit";

const RecruitPage = () => (
  <Layout>
    <Seo
      title="채용정보"
      description="올리브영 Tech 조직의 상시 채용 프로세스, 인재영입, 채용 포지션을 소개합니다"
      url="https://career.oliveyoung.com/"
    />
    <RecruitModule />
  </Layout>
);

export default RecruitPage;
